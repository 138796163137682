<supy-dialog
  class="supy-integration-connect-dialog"
  initialState="open"
  position="center"
  width="30vw"
  (dialogClosed)="onDialogClosed($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  (dialogClosing)="onDialogClosing($event)"
>
  <supy-dialog-header>
    <div class="supy-integration-connect-dialog__header">
      <h3 i18n="@@integrations.connectTenant">Connect {{ providerDisplayName() }}</h3>
      <supy-separator type="solid" class="supy-integration-connect-dialog__separator"></supy-separator>
    </div>
  </supy-dialog-header>
  <supy-dialog-content *ngIf="form" class="supy-integration-connect-dialog__content">
    <form class="supy-integration-connect-dialog__form" [formGroup]="form">
      <div *ngFor="let formConfig of formConfigs">
        <span i18n="@@integrations.enterConfig">Enter {{ formConfig.label }}</span>
        <supy-input
          placeholder="{{ formConfig.placeholder }}"
          formControlName="{{ formConfig.name }}"
          name="{{ formConfig.name }}"
          type="{{ formConfig.type ? formConfig.type : 'text' }}"
        >
          <supy-input-error *supyErrorIf="'required'" i18n="@@integrations.tenantConfigRequired"
            >Please enter {{ formConfig.label }}</supy-input-error
          >
          <supy-input-error *supyErrorIf="'pattern'" i18n="@@integrations.tenantConfigInvalid"
            >Please enter a valid {{ formConfig.label }}</supy-input-error
          >
        </supy-input>
      </div>
    </form>
  </supy-dialog-content>
  <supy-dialog-footer class="supy-integration-connect-dialog__footer">
    <supy-button name="cancel" color="default" (buttonClick)="closeDialog()" i18n="@@cancel">Cancel</supy-button>
    <supy-button
      name="integration-enable"
      type="submit"
      color="primary"
      (buttonClick)="onSubmit()"
      i18n="@@common.actions.enable"
      >Enable</supy-button
    >
  </supy-dialog-footer>
</supy-dialog>
