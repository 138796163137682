import { RetailerId } from '@supy/common';

import { ProviderEnum } from './tenant.entity';

export enum TenantRegistryResource {
  Location = 'location',
}

export enum TenantRegistryStateEnum {
  Active = 'active',
  Deleted = 'deleted',
}

export interface TenantRegistry {
  readonly id: string;
  readonly resource: TenantRegistryResource;
  readonly resourceId: string;
  readonly tenantId: string;
  readonly createdBy: string;
  readonly state: TenantRegistryStateEnum;
  readonly retailerId: RetailerId;
  readonly providerBrand: ProviderEnum;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}
