import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';
import { IANATimezone } from '@supy.api/dictionaries';

import { Destroyable, getRetailerTimeZoneHelperMessage, getShiftedDate } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';
import { getLocalizedName } from '@supy/settings';

import { ProviderBranch, ResourceTypeEnum } from '../../../tenant';
import { BranchWithMapping, CreateMappingRequest, UpdateMappingRequest } from '../../models';

interface BranchMappingFormValue {
  providerResource: ProviderBranch | string;
  startingDate: Date;
  lastSalesSync: Date;
  autoSync: boolean;
}

@Component({
  selector: 'supy-create-branch-mapping-dialog',
  styleUrls: ['./create-branch-mapping-dialog.component.scss'],
  templateUrl: 'create-branch-mapping-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateBranchMappingDialogComponent extends Destroyable implements IDialogComponent, OnInit {
  @ViewChild(DialogComponent, { static: true }) private readonly dialog: DialogComponent;

  @Input() readonly showAutoSync: boolean;
  @Input() readonly isManualProviderBranch: boolean;
  @Input() set showStartingDate(value: boolean) {
    if (value) {
      this.form.addControl('startingDate', new FormControl(null, { validators: Validators.required }));
    } else {
      this.form.removeControl('startingDate');
    }
    this.form.updateValueAndValidity();

    this.#showStartingDate = value;
  }

  get showStartingDate(): boolean {
    return this.#showStartingDate;
  }

  #showStartingDate: boolean;

  @Input() readonly branchWithMapping: BranchWithMapping;
  @Input() readonly providerBranches: ProviderBranch[] = [];
  @Input() readonly utcOffset: number;
  @Input() readonly tenantId: string;
  @Input() readonly ianaTimeZone: IANATimezone;
  @Input() set cutoff(value: number) {
    const date = new Date();

    date.setHours(0, 0, 0, value);

    this.branchCutoffTime.set(date.toTimeString());
  }

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();

  @Output() readonly connect = new EventEmitter<CreateMappingRequest>();
  @Output() readonly save = new EventEmitter<{ id: string; payload: UpdateMappingRequest }>();

  get isEdit(): boolean {
    return !!this.branchWithMapping?.id;
  }

  protected readonly getRetailerTimeZoneHelperMessage = getRetailerTimeZoneHelperMessage;
  protected form = new UntypedFormGroup({
    providerResource: new FormControl(null, { validators: Validators.required }),
    autoSync: new FormControl(),
  });

  protected readonly branchCutoffTime = signal('');

  ngOnInit(): void {
    if (this.isEdit) {
      this.form = new FormGroup({
        providerResource: new FormControl({
          value: this.isManualProviderBranch
            ? this.branchWithMapping?.providerResource?.id
            : this.providerBranches.find(({ id }) => id === this.branchWithMapping?.providerResource?.id),
          disabled: true,
        }),
        startingDate: new FormControl({ value: this.branchWithMapping.metadata?.startingDate, disabled: true }),
        lastSalesSync: new FormControl(this.branchWithMapping.metadata?.lastSalesSync, {
          validators: Validators.required,
        }),
        autoSync: new FormControl(this.branchWithMapping.metadata?.autoSync),
      });
      this.form.updateValueAndValidity();
    }
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onConnect(): void {
    if (this.form.valid) {
      const formValue = this.form.getRawValue() as BranchMappingFormValue;
      const providerResource = this.isManualProviderBranch
        ? { id: formValue.providerResource as string }
        : { id: (formValue.providerResource as ProviderBranch)?.id };

      this.connect.emit({
        tenantId: this.tenantId,
        resourceType: ResourceTypeEnum.Branch,
        supyResource: { id: this.branchWithMapping.branchId },
        providerResource,
        metadata: {
          startingDate: this.getRetailerTimeZoneShiftedDate(new Date(formValue.startingDate)),
          autoSync: !!formValue.autoSync,
        },
      });
    }
  }

  onSave(): void {
    if (this.form.valid) {
      const formValue = this.form.getRawValue() as BranchMappingFormValue;

      this.save.emit({
        id: this.branchWithMapping.id as string,
        payload: {
          metadata: {
            lastSalesSync: this.getRetailerTimeZoneShiftedDate(new Date(formValue.lastSalesSync)),
            autoSync: !!formValue.autoSync,
          },
        },
      });
    }
  }

  private getRetailerTimeZoneShiftedDate(date: Date): Date {
    return getShiftedDate(date, this.utcOffset);
  }

  protected readonly getLocalizedName = getLocalizedName;
}
