import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { TENANT_BFF_URI } from '../config';
import {
  ProviderBranch,
  ProviderCustomer,
  ProviderEnum,
  ProviderItem,
  ProviderItemCategory,
  ProviderLocation,
  ProviderSalesType,
  ProviderSupplier,
  ProviderTaxRate,
  ProviderTenant,
  SetTenantRegistryRequest,
  Tenant,
  TenantActivateRequest,
  TenantBranchesCountResponse,
  TenantLinkData,
  TenantManualSyncResourcesRequest,
  TenantRegistry,
  TenantUpdateRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(TENANT_BFF_URI) private readonly bffUri: string,
  ) {}

  getTenantRegistries(tenantId: string, retailerId: string): Observable<IQueryResponse<TenantRegistry>> {
    return this.httpClient.get<IQueryResponse<TenantRegistry>>(
      `${this.bffUri}/${tenantId}/tenant-registries?retailerId=${retailerId}`,
    );
  }

  getTenants(query: Query<Tenant>): Observable<IQueryResponse<Tenant>> {
    return this.httpClient.get<IQueryResponse<Tenant>>(`${this.bffUri}`, {
      params: query.toQueryParams(),
    });
  }

  getMappedBranchesCount(tenantIds: string[]): Observable<TenantBranchesCountResponse> {
    return this.httpClient.post<TenantBranchesCountResponse>(`${this.bffUri}/mapped-branches-count`, { tenantIds });
  }

  getTenant(id: string): Observable<Tenant> {
    return this.httpClient.get<Tenant>(`${this.bffUri}/${id}`);
  }

  setTenantRegistries(tenantId: string, payload: SetTenantRegistryRequest): Observable<void> {
    return this.httpClient.put<void>(`${this.bffUri}/${tenantId}/tenant-registries`, payload);
  }

  deleteTenant(tenantId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.bffUri}/${tenantId}/archive`, null);
  }

  reconnectTenant(tenantId: string, code: string): Observable<void> {
    return this.httpClient.post<void>(`${this.bffUri}/${tenantId}/reconnect`, { code });
  }

  updateTenant(tenantId: string, body: TenantUpdateRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${tenantId}`, body);
  }

  activateTenant(tenantId: string, body: TenantActivateRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${tenantId}/activate`, body);
  }

  linkTenant(provider: ProviderEnum, linkData: TenantLinkData) {
    return this.httpClient.post<Tenant>(`${this.bffUri}/${this.getProviderLink(provider)}`, linkData);
  }

  getProviderTenants(tenantId: string): Observable<IQueryResponse<ProviderTenant>> {
    return this.httpClient.get<IQueryResponse<ProviderTenant>>(`${this.bffUri}/${tenantId}/provider/tenants`);
  }

  getProviderBranches(tenantId: string, forAccounting?: boolean): Observable<IQueryResponse<ProviderBranch>> {
    if (forAccounting) {
      return this.httpClient.get<IQueryResponse<ProviderBranch>>(
        `${this.bffUri}/${tenantId}/provider/branches?type=accounting`,
      );
    }

    return this.httpClient.get<IQueryResponse<ProviderBranch>>(`${this.bffUri}/${tenantId}/provider/branches`);
  }

  getProviderLocations(tenantId: string): Observable<IQueryResponse<ProviderLocation>> {
    return this.httpClient.get<IQueryResponse<ProviderLocation>>(`${this.bffUri}/${tenantId}/provider/locations`);
  }

  getProviderSuppliers(tenantId: string): Observable<IQueryResponse<ProviderSupplier>> {
    return this.httpClient.get<IQueryResponse<ProviderSupplier>>(`${this.bffUri}/${tenantId}/provider/suppliers`);
  }

  getProviderTaxRates(tenantId: string): Observable<IQueryResponse<ProviderTaxRate>> {
    return this.httpClient.get<IQueryResponse<ProviderTaxRate>>(`${this.bffUri}/${tenantId}/provider/tax-rates`);
  }

  syncProviderResources(tenantId: string, body: TenantManualSyncResourcesRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.bffUri}/${tenantId}/provider-resources`, body);
  }

  getProviderAccountCodes(tenantId: string): Observable<IQueryResponse<ProviderItemCategory>> {
    return this.httpClient.get<IQueryResponse<ProviderItemCategory>>(
      `${this.bffUri}/${tenantId}/provider/account-codes`,
    );
  }

  getProviderItems(credentialId: string): Observable<IQueryResponse<ProviderItem>> {
    return this.httpClient.get<IQueryResponse<ProviderItem>>(`${this.bffUri}/${credentialId}/provider/items`);
  }

  getProviderSalesTypes(tenantId: string, providerBranchId?: string): Observable<IQueryResponse<ProviderSalesType>> {
    return this.httpClient.get<IQueryResponse<ProviderSalesType>>(
      `${this.bffUri}/${tenantId}/provider/sales-types${providerBranchId ? '/' + providerBranchId : ''}`,
    );
  }

  getProviderCustomers(tenantId: string): Observable<IQueryResponse<ProviderCustomer>> {
    return this.httpClient.get<IQueryResponse<ProviderCustomer>>(`${this.bffUri}/${tenantId}/provider/customers`);
  }

  getProviderLink(provider: ProviderEnum): string {
    switch (provider) {
      case ProviderEnum.LightSpeed:
        return 'lightspeed-k';
      case ProviderEnum.LightSpeedO:
        return 'lightspeed-o';
      case ProviderEnum.LightSpeedX:
        return 'lightspeed-x';
      default:
        return provider;
    }
  }
}
