import { ChangeDetectionStrategy, Component, EventEmitter, Output, signal, ViewChild } from '@angular/core';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';

import { TenantSyncMethod } from '../../models';

@Component({
  selector: 'supy-select-tenant-sync-method-dialog',
  templateUrl: './select-tenant-sync-method-dialog.component.html',
  styleUrls: ['./select-tenant-sync-method-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectTenantSyncMethodDialogComponent extends Destroyable implements IDialogComponent {
  @ViewChild(DialogComponent, { static: true }) private readonly dialog: DialogComponent;

  protected readonly method = signal<TenantSyncMethod>(TenantSyncMethod.Pull);

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly valueChange = new EventEmitter<TenantSyncMethod>();

  protected readonly methods = [
    {
      label: 'API',
      value: TenantSyncMethod.Pull,
    },
    {
      label: 'Webhook',
      value: TenantSyncMethod.Push,
    },
  ];

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onSubmit(): void {
    this.dialog.closeDialog();
    this.valueChange.emit(this.method());
  }
}
