import { Workbook } from 'exceljs';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, signal, ViewChild } from '@angular/core';
import { UploadFile } from '@angular-ex/uploader';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';

import { Destroyable, MimeType } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';

@Component({
  selector: 'supy-import-sales-items-dialog',
  styleUrls: ['import-sales-items-dialog.component.scss'],
  templateUrl: 'import-sales-items-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportSalesItemsDialogComponent extends Destroyable implements IDialogComponent {
  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly submitImport = new EventEmitter<{ sheetName: string; file: File }>();

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  protected readonly sheetNames = signal<string[]>([]);
  protected readonly mimeType = MimeType;
  protected readonly maxFileSize = 5000000;

  private file: File | null;
  private selectedSheetName: string;

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onLocalFileUploaded(event: UploadFile[]): void {
    if (!event.length) {
      return;
    }

    this.file = event.at(0)?.nativeFile as File;
    this.readSheetData();
  }

  onChangeFiles(): void {
    this.file = null;
    this.sheetNames.set([]);
  }

  onSheetSelected(name: string): void {
    this.selectedSheetName = name;
  }

  protected onSubmit(): void {
    if (!this.file || !this.selectedSheetName) {
      return;
    }

    this.submitImport.emit({ file: this.file, sheetName: this.selectedSheetName });
  }

  private readSheetData(): void {
    const reader: FileReader = new FileReader();

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const workBook = await new Workbook().xlsx.load(e.target?.result as ArrayBuffer);

      this.sheetNames.set(workBook.worksheets.map(({ name }) => name));
    };

    reader.readAsArrayBuffer(this.file as File);
  }
}
