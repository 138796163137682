import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';

import { INTEGRATION_PROVIDER_CONFIG, IntegrationPropConfig, ProviderEnum, TenantLinkData } from '../../models';

@Component({
  selector: 'supy-integration-connect-dialog',
  templateUrl: './integration-connect-dialog.component.html',
  styleUrls: ['./integration-connect-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationConnectDialogComponent extends Destroyable implements IDialogComponent {
  @ViewChild(DialogComponent, { static: true }) private readonly dialog: DialogComponent;

  protected form: FormGroup;

  protected formConfigs: IntegrationPropConfig[];

  protected providerValue: ProviderEnum;
  protected providerDisplayName = signal('');

  @Input() set provider(value: ProviderEnum) {
    if (value) {
      const integrationProviderConfig = INTEGRATION_PROVIDER_CONFIG[value];

      this.providerDisplayName.set(integrationProviderConfig.displayName);
      this.providerValue = value;
      this.formConfigs = integrationProviderConfig.props as IntegrationPropConfig[];
      this.setupForm();
    }
  }

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly connect = new EventEmitter<TenantLinkData>();

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onSubmit(): void {
    if (this.form.valid) {
      const formValue = this.form.getRawValue() as TenantLinkData;

      this.connect.emit(formValue);
      this.dialog.closeDialog();
    }
  }

  private setupForm(): void {
    const formGroup: { [control: string]: FormControl } = {};

    for (const control of this.formConfigs) {
      if (control.pattern) {
        formGroup[control.name] = new FormControl<string>('', [
          Validators.required,
          Validators.pattern(control.pattern),
        ]);
      } else {
        formGroup[control.name] = new FormControl<string>('', [Validators.required]);
      }
    }

    this.form = new FormGroup(formGroup);
  }
}
