<div class="supy-accounting-mapping-menu">
  <h5 class="supy-accounting-mapping-menu__title" i18n="@@integrations.accountingMapping.menu.title">
    Choose your Tenant
  </h5>
  <div class="supy-accounting-mapping-menu__items" *ngIf="!isLoading; else loadingTemplate">
    <div
      class="supy-accounting-mapping-menu__item"
      *ngFor="let tenant of tenants"
      (click)="onSelectTenant(tenant)"
      [class.supy-accounting-mapping-menu__item--selected]="tenant.id === tenantId"
      [class.supy-accounting-mapping-menu__item--disabled]="tenant.status !== tenantHealth.OK"
    >
      <div class="supy-accounting-mapping-menu__item--icon">
        <supy-image src="/assets/images/{{ tenant.providerBrand }}-logo.svg" height="100%" width="100%"></supy-image>
      </div>
      <div class="supy-accounting-mapping-menu__item--info">
        <h5 class="supy-accounting-mapping-menu__item--info-name">{{ tenant.name }}</h5>
        <strong>
          {{ getProviderDisplayName(tenant.providerBrand) }}
        </strong>
      </div>
      <supy-icon
        class="supy-accounting-mapping-menu__item--arrow"
        [class.supy-accounting-mapping-menu__item--arrow-selected]="tenant.id === tenantId"
        name="arrow-circle-right"
        color="gray"
      ></supy-icon>
    </div>
  </div>
  <ng-template #loadingTemplate>
    <div class="supy-accounting-mapping-menu__items">
      <div class="supy-accounting-mapping-menu__loading" *ngFor="let item of [1, 2, 3]"></div>
    </div>
  </ng-template>
</div>
