import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';
import { Writable } from '@supy/core';

import { ProviderSalesType } from '../../../tenant';

@Component({
  selector: 'supy-add-sales-types-dialog',
  templateUrl: './add-sales-types-dialog.component.html',
  styleUrls: ['./add-sales-types-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSalesTypesDialogComponent extends Destroyable implements IDialogComponent {
  @Input() readonly existingSalesTypes: ProviderSalesType[];

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly confirm = new EventEmitter<ProviderSalesType[]>();
  @Output() readonly cancel = new EventEmitter<void>();

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  protected readonly rows = signal<{ index: number; salesType: Writable<ProviderSalesType> }[]>([
    {
      index: 0,
      salesType: { code: null, name: null },
    },
  ]);

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onCancelClick(): void {
    this.closeDialog();
    this.cancel.emit();
  }

  isValid(): boolean {
    return [...this.rows().map(({ salesType }) => salesType), ...this.existingSalesTypes].reduce(
      (group, salesType) => {
        const codesCount = (group.codes.get(salesType.code) ?? 0) + 1;
        const namesCount = (group.names.get(salesType.name) ?? 0) + 1;

        if (codesCount > 1 || namesCount > 1 || !salesType.code || !salesType.name) {
          group.isValid = false;
        }

        group.codes.set(salesType.code, codesCount);
        group.names.set(salesType.name, namesCount);

        return group;
      },
      { codes: new Map<string, number>(), names: new Map<string, number>(), isValid: true },
    ).isValid;
  }

  onAddRowClick(): void {
    this.rows.update(rows => [...rows, { index: rows.length, salesType: { code: null, name: null } }]);
  }

  onDeleteRowClick(index: number): void {
    this.rows.update(rows => rows.filter(row => row.index !== index));
  }

  onConfirmClick(): void {
    this.closeDialog();

    this.confirm.emit(this.rows().map(({ salesType }) => salesType));
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
