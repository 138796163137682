import { IdType, LocalizedData, SimpleUser } from '@supy/common';

export class SalesTransactionLinkedRecipe {
  readonly id: string;
  readonly name: LocalizedData;
  readonly locationsIds?: string[];
  readonly costCenter?: IdType;
  readonly isLocallyPatched?: boolean;
}

export class SalesTotals {
  readonly discount?: number;
  readonly inclTax: number;
  readonly exclTax: number;
}

export enum SalesTransactionStateEnum {
  Ignored = 'ignored',
  Unmapped = 'unmapped',
  Unlinked = 'unlinked',
  Canceled = 'canceled',
  Synced = 'synced',
  Deleted = 'deleted',
  Processed = 'processed',
}

export interface SalesTransactionItem {
  readonly code: string;
  readonly name: string;
  readonly ignored?: string;
  readonly nameModified?: string;
}

export interface SalesTransaction {
  readonly id: string;
  readonly date: Date;
  readonly posItem: SalesTransactionItem;
  readonly linkedRecipe?: SalesTransactionLinkedRecipe | null;
  readonly parentTransactionId?: string;
  readonly salesType?: SalesTransactionSalesType;
  readonly providerOrderId?: string;
  readonly salesOrderId?: string;
  readonly quantity: number;
  readonly totalSales: SalesTotals;
  readonly state: SalesTransactionStateEnum;
  readonly tenantId: string;
  readonly parentImport: IdType;
  readonly businessDay: string;
  readonly createdBy?: SimpleUser;
  readonly updatedBy?: SimpleUser;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly parentItem?: SalesTransactionItem;
  readonly hasParent: boolean;
}

export interface SalesTransactionSalesType {
  readonly providerCode: string;
  readonly retailerSalesTypeId?: string;
  readonly retailerSalesTypeName?: string;
}
