import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SupyCommonModule } from '@supy/common';
import { ButtonModule, DialogModule, FileUploadModule, IconModule, PageModule, SelectModule } from '@supy/components';

import { ImportPosItemsDialogComponent } from './components';

@NgModule({
  declarations: [ImportPosItemsDialogComponent],
  exports: [ImportPosItemsDialogComponent],
  imports: [
    CommonModule,
    SupyCommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    IconModule,
    PageModule,
    FileUploadModule,
    DialogModule,
    SelectModule,
  ],
})
export class PosItemModule {}
