import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BadgeStatus } from '@supy/components';

import { TenantHealthEnum } from '../../models';

@Component({
  selector: 'supy-tenant-status-badge',
  templateUrl: './tenant-status-badge.component.html',
  styleUrls: ['./tenant-status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantStatusBadgeComponent {
  @Input() readonly status: string;
  @Input() readonly text?: string;

  get statusText() {
    return this.text ?? this.status;
  }

  readonly organizationStatusMapper: Record<string, BadgeStatus> = {
    [TenantHealthEnum.Error]: 'error',
    [TenantHealthEnum.Good]: 'success',
    [TenantHealthEnum.Incomplete]: 'warn',
  };
}
