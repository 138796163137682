import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';
import { InventoryRecipe } from '@supy/inventory';

import { POS_ITEMS_BFF_URI } from '../config';
import {
  IgnorePosItemRequest,
  ManualSyncPosItemsRequest,
  ManualSyncPosItemsResponse,
  MapPosItemRequest,
  MapPosItemsRequest,
  PosItem,
  PosItemRecipeRequestProps,
  PosItemRequestProps,
  PosItemsStats,
  ProviderSyncPosItemsResponse,
  UnignorePosItemRequest,
} from '../models';

@Injectable({ providedIn: 'root' })
export class PosItemService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(POS_ITEMS_BFF_URI) private readonly bffUri: string,
  ) {}

  get(query: Query<PosItem & PosItemRequestProps>): Observable<IQueryResponse<PosItem>> {
    return this.httpClient.get<IQueryResponse<PosItem>>(`${this.bffUri}`, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  getStats(tenantId: string): Observable<PosItemsStats> {
    return this.httpClient.get<PosItemsStats>(`${this.bffUri}/stats?tenantId=${tenantId}`);
  }

  getRecipes(query: Query<InventoryRecipe & PosItemRecipeRequestProps>): Observable<IQueryResponse<InventoryRecipe>> {
    return this.httpClient.get<IQueryResponse<InventoryRecipe>>(`${this.bffUri}/recipes`, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  mapItem(id: string, body: MapPosItemRequest): Observable<PosItem> {
    return this.httpClient.patch<PosItem>(`${this.bffUri}/${id}/map`, body);
  }

  mapItems(body: MapPosItemsRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/map-batch`, body);
  }

  unmapItem(id: string): Observable<PosItem> {
    return this.httpClient.patch<PosItem>(`${this.bffUri}/${id}/unmap`, null);
  }

  ignoreItem(id: string, body: IgnorePosItemRequest): Observable<PosItem> {
    return this.httpClient.patch<PosItem>(`${this.bffUri}/${id}/ignore`, body);
  }

  unignoreItem(id: string, body: UnignorePosItemRequest): Observable<PosItem> {
    return this.httpClient.patch<PosItem>(`${this.bffUri}/${id}/unignore`, body);
  }

  manualSync(body: ManualSyncPosItemsRequest): Observable<ManualSyncPosItemsResponse> {
    return this.httpClient.post<ManualSyncPosItemsResponse>(`${this.bffUri}/manual-sync`, body);
  }

  providerSync(tenantId: string): Observable<ProviderSyncPosItemsResponse> {
    return this.httpClient.post<ProviderSyncPosItemsResponse>(`${this.bffUri}/provider-sync`, {
      tenantId,
    });
  }
}
