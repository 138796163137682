import { ManualSyncPosItemsRequest, MapPosItemRequest, MapPosItemsRequest, UnignorePosItemRequest } from '../../models';
import { PosItemFilters, PosItemRequestMetadata } from '../state';

export class PosItemGet {
  static readonly type = '[PosItem] Get';
  constructor(public payload: { id: string; fromCache?: boolean }) {}
}

export class PosItemGetMany {
  static readonly type = '[PosItem] GetMany';
}

export class PosItemInitFilters {
  static readonly type = '[PosItem] InitFilters';
}

export class PosItemPatchFilterItems {
  static readonly type = '[PosItem] PatchFilterItems';
  constructor(public payload: Partial<PosItemFilters>) {}
}

export class PosItemPatchFilterRecipes {
  static readonly type = '[PosItem] PatchFilterRecipes';
  constructor(public payload: Partial<PosItemFilters>) {}
}

export class PosItemResetFilters {
  static readonly type = '[PosItem] ResetFilters';
}

export class PosItemPatchRequestMeta {
  static readonly type = '[PosItem] PatchRequestMeta';
  constructor(public payload: Partial<PosItemRequestMetadata>) {}
}

export class PosItemManualSync {
  static type = '[PosItem] ManualSync';

  constructor(readonly payload: { retailerId: string; body: ManualSyncPosItemsRequest }) {}
}

export class PosItemMap {
  static type = '[PosItem] Map';

  constructor(
    readonly id: string,
    readonly payload: MapPosItemRequest,
  ) {}
}

export class PosItemMapMany {
  static type = '[PosItem] MapMany';

  constructor(readonly payload: MapPosItemsRequest) {}
}

export class PosItemUnmap {
  static type = '[PosItem] Unmap';

  constructor(readonly id: string) {}
}

export class PosItemIgnore {
  static type = '[PosItem] Ignore';

  constructor(
    readonly id: string,
    readonly requestBody: UnignorePosItemRequest,
  ) {}
}

export class PosItemUnignore {
  static type = '[PosItem] Unignore';

  constructor(
    readonly id: string,
    readonly requestBody: UnignorePosItemRequest,
  ) {}
}

export class PosItemGetRecipes {
  static readonly type = '[PosItem] Recipes';
}

export class PosItemGetStats {
  static type = '[PosItem] GetStats';
}

export class PosItemResetProviderSyncStatus {
  static type = '[PosItem] ResetProviderSyncStatus';
}

export class PosItemProviderSync {
  static type = '[PosItem] ProviderSync';

  constructor(readonly tenantId: string) {}
}

export class PosItemSetProviderSyncStatusListener {
  static type = '[PosItem] SetProviderSyncStatusListener';
}

export class PosItemDisposeProviderSyncStatusListener {
  static type = '[PosItem] DisposeProviderSyncStatusListener';
}

export class PosItemExport {
  static type = '[PosItem] Export';

  constructor(readonly tenantName: string) {}
}
