import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { IGridCellEventArgs } from '@infragistics/igniteui-angular';

import { INTEGRATION_PROVIDER_CONFIG, ProviderEnum, Tenant, TenantCategoryEnum } from '../../models';

interface Integration {
  readonly category: TenantCategoryEnum;
  readonly providerBrand: ProviderEnum;
  readonly tenantsCount: number;
}

@Component({
  selector: 'supy-integrations-grid',
  templateUrl: './integrations-grid.component.html',
  styleUrls: ['./integrations-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationsGridComponent {
  @Input() set tenants(value: Map<ProviderEnum, Tenant[]>) {
    if (value?.size) {
      const integrations = [];

      for (const [provider, tenants] of value.entries()) {
        integrations.push({
          category: tenants[0].category,
          providerBrand: provider,
          tenantsCount: tenants.length,
        });
      }

      this.integrations.set(integrations);
    }
  }

  @Output() readonly integrationClick = new EventEmitter<Tenant>();

  protected integrations = signal<Integration[]>([]);

  onCellClick(event: IGridCellEventArgs): void {
    this.integrationClick.emit(event.cell.row.data as Tenant);
  }

  protected getProviderDisplayName(provider: ProviderEnum): string {
    return `${INTEGRATION_PROVIDER_CONFIG[provider].displayName}`;
  }
}
