import { LocalizedData, LocalizedSkeletonObjectType, SimpleUser } from '@supy/common';

export enum PosItemStateEnum {
  Unmapped = 'unmapped',
  Mapped = 'mapped',
  Ignored = 'ignored',
  Deleted = 'deleted',
}

export class PosItemMetadata {
  readonly category?: string;
}

export class PosItem {
  readonly id: string;
  readonly retailerId: string;
  readonly tenantId: string;
  readonly recipe?: LocalizedSkeletonObjectType;
  readonly name: LocalizedData;
  readonly code: string;
  readonly state: PosItemStateEnum;
  readonly isModifier?: boolean;
  readonly metadata?: PosItemMetadata;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly createdBy?: SimpleUser;
  readonly updatedBy?: SimpleUser;
}

export type PosItemsStats = {
  [key in PosItemStateEnum | 'total']?: number;
};

export type PosItemsProviderSyncStatus =
  | 'not-started'
  | 'pending'
  | 'started'
  | 'progress'
  | 'finalizing'
  | 'done'
  | 'error';
