import { createWorksheet, DataColumn, saveWorkbook } from '@supy/common';

import { PosItem, PosItemStateEnum } from '../..';

type SheetValue = Record<string, string | number | Date | undefined>;
type SheetValues = Array<SheetValue>;

export async function downloadPosItemsReport(posItems: PosItem[], tenantName: string): Promise<void> {
  const columns: DataColumn[] = [
    { header: 'POS Item ID', key: 'id' },
    { header: 'POS Item Name(* - Modifier)', key: 'name' },
  ];

  const mappedSheetValues: SheetValues = [];
  const unmappedSheetValues: SheetValues = [];
  const ignoredSheetValues: SheetValues = [];

  posItems.forEach(posItem => {
    const sheetRow: SheetValue = {
      id: posItem.code,
      name: posItem.isModifier ? `*${posItem.name.en}` : posItem.name.en,
    };

    switch (posItem.state) {
      case PosItemStateEnum.Mapped:
        mappedSheetValues.push({ ...sheetRow, recipeName: posItem.recipe?.name.en });
        break;
      case PosItemStateEnum.Unmapped:
        unmappedSheetValues.push(sheetRow);
        break;
      case PosItemStateEnum.Ignored:
        ignoredSheetValues.push(sheetRow);
        break;
    }
  });

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();

  await createWorksheet(workbook, 'Unmapped', { data: unmappedSheetValues, columns });
  await createWorksheet(workbook, 'Mapped', {
    data: mappedSheetValues,
    columns: [...columns, { header: 'Recipe Name', key: 'recipeName' }],
  });
  await createWorksheet(workbook, 'Ignored', { data: ignoredSheetValues, columns });

  saveWorkbook(workbook, `pos-items-${tenantName}-${Date.now()}.xlsx`);
}
