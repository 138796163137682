import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { ACCOUNTING_INTEGRATION_BFF_URI } from '../config';

@Injectable({
  providedIn: 'root',
})
export class AccountingIntegrationService {
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(ACCOUNTING_INTEGRATION_BFF_URI) private readonly bffUri: string,
  ) {}

  syncGrn(grnId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.bffUri}/grns/${grnId}/provider-invoices`, null);
  }
}
