import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { GridPagingMode } from '@infragistics/igniteui-angular';

import { MappingGridEntry, MappingResource } from '../../../mapping';
import { ResourceTypeEnum, TenantHealthEnum } from '../../../tenant';

@Component({
  selector: 'supy-accounting-mapping-grid',
  templateUrl: './accounting-mapping-grid.component.html',
  styleUrls: ['./accounting-mapping-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountingMappingGridComponent {
  @Input() readonly supyColumnHeader: string;
  @Input() readonly providerColumnHeader: string;
  @Input() readonly paginationMode: GridPagingMode;
  @Input() readonly page: number;
  @Input() readonly perPage: number;
  @Input() readonly prevPageDisabled: boolean;
  @Input() readonly nextPageDisabled: boolean;
  @Input() readonly data: MappingGridEntry[];
  @Input() readonly providerMappingValues: MappingResource[];
  @Input() readonly isReadonly: boolean;
  @Input() set resourceType(value: ResourceTypeEnum) {
    this.searchValue.set(null);
  }

  @Output() readonly mappingChange = new EventEmitter<MappingGridEntry>();
  @Output() readonly pageChange = new EventEmitter<number>();

  protected searchValue = signal<string | null>(null);

  protected readonly statusMap: Record<string, string> = {
    [TenantHealthEnum.Good]: $localize`:@@integrations.accountMapping.grid.status.good:Mapped`,
    [TenantHealthEnum.Error]: $localize`:@@integrations.accountMapping.grid.status.error:Invalid Mapping`,
    [TenantHealthEnum.Incomplete]: $localize`:@@integrations.accountMapping.grid.status.incomplete:Unmapped`,
  };

  protected onMappingChange(value: string, entry: MappingGridEntry): void {
    this.mappingChange.emit({
      ...entry,
      secondaryResource: value ? this.getProviderMappingValue(value) : null,
    });
  }

  protected getProviderMappingValue(id: string): MappingResource | undefined {
    return this.providerMappingValues.find(value => value.id === id);
  }
}
