import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SupyCommonModule } from '@supy/common';
import {
  ButtonModule,
  DialogModule,
  FileUploadModule,
  GridModule,
  IconModule,
  ImageModule,
  InputModule,
  LoadingOverlayModule,
  PageModule,
  SelectModule,
} from '@supy/components';

import { TenantModule } from '../tenant';
import {
  AccountingMappingGridComponent,
  AccountingMappingMenuComponent,
  ImportAccountingResourcesDialogComponent,
} from './components';

@NgModule({
  declarations: [
    AccountingMappingGridComponent,
    AccountingMappingMenuComponent,
    ImportAccountingResourcesDialogComponent,
  ],
  exports: [AccountingMappingGridComponent, AccountingMappingMenuComponent, ImportAccountingResourcesDialogComponent],
  imports: [
    CommonModule,
    ButtonModule,
    GridModule,
    IconModule,
    ImageModule,
    InputModule,
    DialogModule,
    LoadingOverlayModule,
    SelectModule,
    TenantModule,
    SupyCommonModule,
    FormsModule,
    ReactiveFormsModule,
    PageModule,
    FileUploadModule,
  ],
})
export class AccountingIntegrationModule {}
