import { LocalizedSkeletonObjectType } from '@supy/common';

import { ProviderEnum, ResourceTypeEnum, TenantSyncMethod } from '../../tenant';
import { MappingCategoryEnum } from './mapping.model';

export enum MappingStateEnum {
  Active = 'active',
  Inactive = 'inactive',
}

export interface MappingResource {
  readonly id: string;
  readonly name: string;
  readonly trackingCategoryId?: string | null;
  readonly metadata?: Record<string, unknown>;
}

export class Mapping {
  readonly id: string;
  readonly retailerId: string;
  readonly tenantId: string;
  readonly state: MappingStateEnum;
  readonly type: ResourceTypeEnum;
  readonly providerResource?: MappingResource;
  readonly supyResource: MappingResource;
  readonly metadata: MappingMetadata;
  readonly updatedAt: Date;
  readonly createdAt: Date;
  readonly activeProviders: ProviderEnum[];
  readonly category: MappingCategoryEnum;
  readonly resourceType: ResourceTypeEnum;
}

export interface MappingMetadata {
  readonly startingDate?: Date;
  readonly lastSalesSync?: Date;
  readonly autoSync?: boolean;
  readonly referenceId?: string;
}

export interface BranchesWithTenants {
  readonly branches: BranchWithTenants[];
}

export interface BranchWithTenants {
  readonly branch: LocalizedSkeletonObjectType;
  readonly tenants: BranchTenant[];
}

export interface BranchTenant {
  readonly id: string;
  readonly name: string;
  readonly lastSyncDate: Date;
  readonly providerBrand: ProviderEnum;
  readonly autoSync: boolean;
  readonly syncMethod: TenantSyncMethod;
  readonly blockProviderItemsSync: boolean;
  readonly blockProviderSalesImportSync: boolean;
  readonly blockProviderSalesTypesMapping: boolean;
}
