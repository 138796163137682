import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import { getRetailerTimeZoneHelperMessage, LocalizedSkeletonObjectType, MimeType } from '@supy/common';
import { getLocalizedName } from '@supy/settings';

import { BranchTenant, BranchWithTenants } from '../../../mapping';
import { INTEGRATION_PROVIDER_CONFIG, ProviderEnum } from '../../../tenant';
import { SalesImport } from '../../models';

export interface SalesImportFormDetails {
  readonly description: string;
  readonly branch: LocalizedSkeletonObjectType;
  readonly tenant: BranchTenant;
}

@Component({
  selector: 'supy-sales-import-details',
  templateUrl: './sales-import-details.component.html',
  styleUrls: ['./sales-import-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesImportDetailsComponent implements OnChanges {
  @Input() readonly isReadonly: boolean;
  @Input() readonly salesImport: SalesImport;
  @Input() readonly tenant: BranchTenant;
  @Input() readonly branchesWithTenants: BranchWithTenants[];
  @Input() readonly ianaTimeZone: IANATimezone;
  @Input() readonly utcOffset: number;
  @Input() readonly isProviderSyncInProgress: boolean;
  @Input() readonly currency: Currency;
  @Input() readonly currencyPrecision: number;
  @Input() readonly isIgnoredDisplayed: boolean;
  @Input() readonly uniqueIgnoredItemsCount: number;

  protected readonly isBranchProviderAuth = signal<boolean>(false);
  protected readonly sheetNames = signal<string[]>([]);
  protected readonly mimeType = MimeType;
  protected readonly maxFileSize = 5000000;

  protected readonly providers = ProviderEnum;
  protected readonly getRetailerTimeZoneHelperMessage = getRetailerTimeZoneHelperMessage;

  @Output() readonly tenantSelected = new EventEmitter<{ branchId: string; tenant: BranchTenant }>();
  @Output() readonly manualSync = new EventEmitter<SalesImportFormDetails>();
  @Output() readonly providerSync = new EventEmitter<SalesImportFormDetails>();
  @Output() readonly toggleIgnored = new EventEmitter<void>();

  protected readonly form = new FormGroup({
    description: new FormControl<string | null>(null, [Validators.required]),
    branch: new FormControl<LocalizedSkeletonObjectType | null>(null, [Validators.required]),
    tenant: new FormControl<BranchTenant | null>(null, [Validators.required]),
  });

  get branchTenants(): BranchTenant[] {
    return this.branchesWithTenants.find(({ branch }) => this.form.value.branch?.id === branch.id)?.tenants ?? [];
  }

  get selectedTenant(): BranchTenant | null | undefined {
    return this.form.value.tenant;
  }

  get isProviderSyncPossible(): boolean {
    const currentTenant = this.form.value.tenant;

    return !!(
      currentTenant &&
      currentTenant.providerBrand !== ProviderEnum.Manual &&
      !currentTenant.blockProviderSalesImportSync
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.salesImport?.currentValue as SalesImport) || (changes.tenant?.currentValue as BranchTenant)) {
      this.form.patchValue({
        description: this.salesImport.description,
        branch: this.salesImport.branch,
        tenant: this.tenant,
      });

      this.form.disable();
    }
  }

  getProviderDisplayName(provider: ProviderEnum) {
    return `${INTEGRATION_PROVIDER_CONFIG[provider]?.displayName}`;
  }

  get totalsHeader(): string {
    return this.isIgnoredDisplayed
      ? $localize`:@@integrations.ignoredTotals: Ignored Totals`
      : $localize`:@@integrations.processedTotals: Processed Totals`;
  }

  onProviderSyncClick(): void {
    this.providerSync.emit(this.form.value as SalesImportFormDetails);
  }

  onManualSyncClick(): void {
    this.manualSync.emit(this.form.value as SalesImportFormDetails);
  }

  onBranchSelected(): void {
    this.isBranchProviderAuth.set(this.branchTenants?.length > 1);
    this.form.patchValue({ tenant: null });

    if (!this.isBranchProviderAuth()) {
      this.form.patchValue({ tenant: this.branchTenants.at(0) });
    }
  }

  onTenantSelected(tenant: BranchTenant): void {
    this.tenantSelected.emit({ branchId: this.form.value.branch?.id as string, tenant });
  }

  protected onToggleIgnored(): void {
    this.toggleIgnored.emit();
  }

  protected readonly getLocalizedName = getLocalizedName;
}
