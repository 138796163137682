import { LocalizedSkeletonObjectType, SkeletonObjectType } from '@supy/common';

import { ProviderEnum } from '../../tenant';
import { SalesTotals } from './sales-transaction.entity';

export class SalesImportMetadata {
  readonly fileUrl?: string;
  readonly sheetName?: string;
  readonly autoProcessed?: boolean;
}

export enum SalesImportStateEnum {
  Active = 'active',
  Pending = 'pending',
  Deleted = 'deleted',
  Processed = 'processed',
  PartiallyProcessed = 'partially-processed',
}

export interface SalesImportStats {
  readonly totalIgnoredItems: number;
  readonly totalCanceledItems: number;
  readonly totalSyncedItems: number;
  readonly totalUnlinkedItems: number;
  readonly totalUnmappedItems: number;
  readonly totalHiddenItems: number;
  readonly totalProcessedItems: number;
}

export class SalesImport {
  readonly id: string;
  readonly description: string;
  readonly startDate: Date;
  readonly endDate: Date;
  readonly branch: LocalizedSkeletonObjectType;
  readonly retailer: SkeletonObjectType;
  readonly metadata?: SalesImportMetadata;
  readonly tenantId: string;
  readonly providerBrand: ProviderEnum;
  readonly state: SalesImportStateEnum;
  readonly totalSales: SalesTotals;
  readonly totalIgnoredSales: SalesTotals;
  readonly statistics: SalesImportStats;
  readonly updatedAt?: Date;
  readonly createdAt?: Date;
}

export type SalesImportProviderSyncStatus =
  | 'not-started'
  | 'pending'
  | 'started'
  | 'progress'
  | 'finalizing'
  | 'done'
  | 'error';
