import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IDialogCancellableEventArgs, IDialogEventArgs, OverlaySettings } from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';

import { ProviderEnum, ProviderTenant, TenantActivateRequest } from '../../models';

@Component({
  selector: 'supy-activate-tenant-dialog',
  templateUrl: './activate-tenant-dialog.component.html',
  styleUrls: ['./activate-tenant-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivateTenantDialogComponent extends Destroyable implements IDialogComponent {
  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  @Input() readonly title: string;
  @Input() readonly tenantId: string;
  @Input() readonly providerBrand: ProviderEnum;
  @Input() readonly cancelText?: string = $localize`:@@cancel:Cancel`;
  @Input() readonly confirmText?: string = $localize`:@@common.actions.submit:Submit`;
  @Input() readonly navigateOnConfirm?: boolean;
  @Input() readonly redirectUrl?: string[];
  @Input() readonly overlaySettings?: OverlaySettings;
  @Input() readonly providerTenants?: ProviderTenant[];

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly confirm = new EventEmitter<TenantActivateRequest>();
  @Output() readonly cancel = new EventEmitter<void>();

  protected providers = ProviderEnum;

  protected readonly form = new FormGroup({
    providerTenant: new FormControl<ProviderTenant[]>([]),
    providerCustomFormId: new FormControl<string | null>(null),
    providerSubsidiaryId: new FormControl<string | null>(null),
    providerVendorCreditCustomFormId: new FormControl<string | null>(null),
  });

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onCancelClick(): void {
    this.closeDialog();
    this.cancel.emit();
  }

  onConfirmClick(): void {
    this.closeDialog();

    let payload = {};

    if (this.providerBrand === ProviderEnum.Zoho) {
      const providerTenant = this.form.value.providerTenant?.[0];

      payload = {
        providerTenantId: providerTenant?.id as string,
        providerName: providerTenant?.displayName as string,
      };
    }

    if (this.providerBrand === ProviderEnum.OracleNetsuite) {
      payload = {
        providerCustomFormId: this.form.value.providerCustomFormId as string,
        providerSubsidiaryId: this.form.value.providerSubsidiaryId as string,
        providerVendorCreditCustomFormId: this.form.value.providerVendorCreditCustomFormId as string,
      };
    }

    this.confirm.emit(payload);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
