import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';

import { ProviderBranch } from '../../../tenant';

@Component({
  selector: 'supy-select-provider-branch-dialog',
  styleUrls: ['./select-provider-branch-dialog.component.scss'],
  templateUrl: 'select-provider-branch-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectProviderBranchDialogComponent extends Destroyable implements IDialogComponent {
  @ViewChild(DialogComponent, { static: true }) private readonly dialog: DialogComponent;

  @Input() readonly providerBranches: ProviderBranch[] = [];

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();

  @Output() readonly selected = new EventEmitter<ProviderBranch>();
  @Output() readonly canceled = new EventEmitter<void>();

  protected providerBranch: ProviderBranch;

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onCancel(): void {
    this.canceled.emit();
  }

  onSubmit(): void {
    this.closeDialog();
    this.selected.emit(this.providerBranch);
  }
}
