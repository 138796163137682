<supy-grid
  #grid
  [data]="data"
  primaryKey="id"
  cellSelection="none"
  class="supy-accounting-map-grid"
  [paginationMode]="paginationMode"
  [page]="page"
  [prevPageDisabled]="prevPageDisabled"
  [nextPageDisabled]="nextPageDisabled"
  [perPage]="perPage"
  (pageChange)="pageChange.emit($event)"
>
  <supy-grid-column header="supy {{ supyColumnHeader }}" width="30%" field="primaryResource.name"></supy-grid-column>
  <supy-grid-column [header]="providerColumnHeader" width="30%">
    <ng-template supyGridCell let-rowData="rowData">
      <div (keydown)="$event.stopPropagation()" class="supy-accounting-map-grid__combo-container">
        <supy-select
          *ngIf="!isReadonly; else mappingText"
          placeholder="Choose"
          name="provider-mapping"
          density="small"
          [value]="rowData.secondaryResource?.id"
          [clearable]="true"
          [searchId]="rowData.primaryResource.id"
          [searchEnabled]="true"
          (search)="searchValue.set($event)"
          (opened)="searchValue.set('')"
          (selected)="onMappingChange($event, rowData)"
        >
          <supy-select-item
            *ngFor="let providerMapping of providerMappingValues | selectSearch: searchValue() : 'name'"
            [value]="providerMapping?.id"
            >{{ providerMapping.name | titlecase }}</supy-select-item
          >
          <supy-select-item *ngIf="!providerMappingValues?.length">
            <span i18n="@@integrations.accountingMapping.noResource">Nothing to display</span>
          </supy-select-item>
        </supy-select>
        <ng-template #mappingText>
          <span>{{ rowData.secondaryResource?.name }}</span>
        </ng-template>
      </div>
    </ng-template>
  </supy-grid-column>
  <supy-grid-column header="Status" i18n-header="@@status" width="10%" field="status">
    <ng-template supyGridCell let-value="value">
      <supy-tenant-status-badge [status]="value" [text]="statusMap[value]"></supy-tenant-status-badge>
    </ng-template>
  </supy-grid-column>
</supy-grid>
