import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AccountingIntegrationActionEnum, ResourceEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
export class ManageTenants extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.AccountingIntegration,
      action: AccountingIntegrationActionEnum.ManageTenants,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class UpdateMapping extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.AccountingIntegration,
      action: AccountingIntegrationActionEnum.UpdateMapping,
      resource,
      principal,
    });
  }
}

export class AccountingIntegrationPermissionsStrategy {
  static ManageTenants = ManageTenants;
  static UpdateMapping = UpdateMapping;
}
