import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IntegrationProviderConfig, ProviderAuthTypeEnum, ProviderEnum } from '../../models';

@Component({
  selector: 'supy-integration-tile',
  templateUrl: './integration-tile.component.html',
  styleUrls: ['./integration-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationTileComponent {
  @Input() readonly integration: IntegrationProviderConfig & { providerBrand: ProviderEnum };
  @Output() readonly integrationClick = new EventEmitter();

  protected readonly integrationAuthType = ProviderAuthTypeEnum;

  onClick(): void {
    this.integrationClick.emit();
  }
}
