import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';

import { SupyCommonModule } from '@supy/common';
import {
  ButtonModule,
  DateInputModule,
  DateTimeEditorModule,
  DialogModule,
  GridModule,
  IconModule,
  InputModule,
  LoadingOverlayModule,
  SelectModule,
  SeparatorModule,
  SwitchModule,
  TooltipModule,
} from '@supy/components';

import {
  AddSalesTypesDialogComponent,
  CreateBranchMappingDialogComponent,
  SelectProviderBranchDialogComponent,
} from './components';
import { MappingBranchesGridComponent } from './components/mapping-branches-grid';
import { MappingState } from './store';

@NgModule({
  declarations: [
    CreateBranchMappingDialogComponent,
    MappingBranchesGridComponent,
    AddSalesTypesDialogComponent,
    SelectProviderBranchDialogComponent,
  ],
  exports: [
    CreateBranchMappingDialogComponent,
    MappingBranchesGridComponent,
    AddSalesTypesDialogComponent,
    SelectProviderBranchDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SupyCommonModule,
    GridModule,
    IconModule,
    ButtonModule,
    DialogModule,
    SelectModule,
    DateInputModule,
    SeparatorModule,
    SwitchModule,
    InputModule,
    LoadingOverlayModule,
    DateTimeEditorModule,
    TooltipModule,
    NgxsModule.forFeature([MappingState]),
  ],
})
export class MappingModule {}
